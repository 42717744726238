import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTheMask from "vue-the-mask";
import VueSweetalert2 from "vue-sweetalert2";
import Vue3Geolocation from "vue3-geolocation";
import "sweetalert2/dist/sweetalert2.min.css";

import "./assets/css/tailwind.css";
import "./assets/vendor/font-lotusssmarthl/font-lotusssmarthl.css";
import "./assets/css/custom.css";
// import "./assets/vendor/font-SukhumvitSet/SukhumvitSet.css";
// import "./assets/vendor/font-dbhelvethaicax/font-dbhelvethaicax.css";

createApp(App)
  .use(store)
  .use(router)
  .use(VueTheMask)
  .use(VueSweetalert2)
  .use(Vue3Geolocation)
  .mount("#app");

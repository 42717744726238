<template>
  <div>
    <Layout />
    <router-view />
  </div>
</template>
<script>
import liff from "@line/liff";
import Layout from "@/components/Layout";
import configs from "@/config";

export default {
  name: "App",
  components: {
    Layout,
  },
  data() {
    return { configs };
  },
  async created() {
    try {
      await liff.init({
        liffId: this.configs.lineLiffId,
      });
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<template>
  <div>
    <div class="content w-full p-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00bcb4"
      ></loading>
      <img
        src="../assets/images/MJN_Cover1040.png"
        class="border-0 border-white rounded-lg"
      />
      <div
        class="bg-gradient-to-b from-yellow-500 via-white to-yellow-300 rounded-lg shadow-lg p-1 mt-5"
      >
        <div class="bg-white rounded-lg p-5">
          <div class="text-2xl text-center font-bold text-yellow-500">
            เช็คยอดสูงสุด
          </div>

          <div class="">
            <div
              class="overflow-x-auto rounded-lg overflow-y-auto relative mt-5"
            >
              <table
                class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
              >
                <thead>
                  <tr class="text-left text-sm bg-blue-800">
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      ลำดับ
                    </th>
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      ชื่อ - นามสกุล
                    </th>
                    <!-- <th
                  class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                >
                  สาขา/ช่องทางที่ซื้อ
                </th> -->
                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      เบอร์โทร
                    </th>

                    <th
                      class="text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      ยอดเงินสะสม
                    </th>
                  </tr>
                </thead>
                <tbody class="text-sm text-gray-700">
                  <tr v-for="(h, index) in winNoList" :key="index">
                    <td
                      class="fz12 text-left border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      {{ h.INDEX }}.
                    </td>
                    <td
                      class="fz12 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      {{ h.CUS_FNAME }} {{ h.CUS_LNAME }}
                    </td>
                    <!-- <td class="fz12 border-b border-gray-200 px-3 py-2">
                  {{ h.CB_SUBMIT_BUY_SHOP_NAME }}
                </td> -->
                    <td
                      class="fz12 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                    >
                      {{ h.CUS_MOBILE }}
                    </td>
                    <td
                      class="fz12 border-b border-gray-200 px-3 py-2 whitespace-nowrap text-right"
                    >
                      {{ h.CB_TOPSPENDER_PRICE }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../router";
import Service from "../service/service";
export default {
  name: "Success",
  props: ["shop"],
  data() {
    return { txtMessage: "", winNoList: [] };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },

  async mounted() {
    this.isLoading = true;
    this.service.getTopSpender(100).then((rez) => {
      console.log(rez.data);
      this.winNoList = rez.data;
    });
    this.isLoading = false;
  },
  methods: {
    goTo(r) {
      router.push(r);
    },
  },
};
</script>

import { createStore } from "vuex";

export default createStore({
  state: { cartProducts: [] },
  getters: { getProductsInCart: (state) => state.cartProducts },
  mutations: {
    UPDATE_CART: (state, cart) => {
      state.cartProducts = cart;
    },
    RESET_CART: (state) => {
      state.cartProducts = [];
    },
  },
  actions: {
    updateCart: (context, cart) => {
      context.commit("UPDATE_CART", cart);
    },
    resetCart: (context) => {
      context.commit("RESET_CART");
    },
  },
  modules: {},
});
